export const schema = {
  password: {
    pattern: /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])).{8,100}$/,
    required: true,
  },
  email: {
    pattern: /[^@]+@[^.](.+)?\.(.+)?/,
    required: true,
  },
  url: {
    required: false,
    validate: {
      invalidUrl: (value: string) => {
        if (!value) return false;
        try {
          const validateUrl = new URL(value);
          if (!validateUrl) return false;
        } catch (error) {
          return false;
        }
        return true;
      },
    },
  },
  title: {
    required: false,
    maxLength: 64,
  },
};
