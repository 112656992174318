import { makeStyles } from 'tss-react/mui';
import { JsonObject } from '../types';
import { IMAGE_URL } from '../constants/const';

export const useCryptoBoxStyles = makeStyles()(() => ({
  root: {
    width: 260,
    boxShadow: 'none !important',
    margin: 12,
  },
  header: {
    backgroundColor: '#f4f5f7',
    background: `radial-gradient(circle at 100% 100%, #ffffff 12px, transparent 0),
                   radial-gradient(circle at 0% 100%, #ffffff 12px, transparent 0)`,
    marginBottom: 6,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    height: '40px',
  },
  center: {
    backgroundColor: '#f4f5f7',
    background: `radial-gradient(circle at 100% 100%, #ffffff 12px, transparent 0),
                   radial-gradient(circle at 0% 100%, #ffffff 12px, transparent 0),
                   radial-gradient(circle at 100% 0%, #ffffff 12px, transparent 0),
                   radial-gradient(circle at 0% 0%, #ffffff 12px, transparent 0)`,
    marginTop: 6,
    marginBottom: 6,
    display: 'flex',
    justifyItems: 'center',
    justifyContent: 'center',
    height: '160px',
  },
  footer: {
    backgroundColor: '#f4f5f7',
    background: `radial-gradient(circle at 100% 0%, #ffffff 12px, transparent 0),
                   radial-gradient(circle at 0% 0%, #ffffff 12px, transparent 0)`,
    marginTop: 6,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    height: '40px',
  },
  address: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    marginLeft: '4px !important',
  },
}));

export const useLinkBoxStyle = makeStyles()(() => ({
  linkBox: {
    backgroundColor: '#f4f5f7',
    padding: '8px',
    textAlign: 'center',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginTop: '6px',
    marginBottom: '6px',
    color: 'inherit',
    hyphens: 'auto',
    textDecoration: 'none',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
  link: {
    color: 'inherit',
    hyphens: 'auto',
    textDecoration: 'none',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
}));

export const useUserStyles = makeStyles()(() => ({
  bigAvatar: {
    margin: 10,
    width: '64px !important',
    height: '64px !important',
  },
  gridBox: {
    width: '100% !important',
    marginLeft: '0px !important',
  },
}));

const getNameInitials = (name: string): string => {
  const splitName = name
    .split(' ')
    .filter(word => word !== '')
    .splice(0, 3);
  return splitName.reduce(
    (acc, currentValue) => `${acc}${currentValue[0]}`,
    '',
  );
};

export const getAvatarProps = (user: JsonObject) => {
  if (!user.name) {
    return {};
  }

  return {
    ...(user?.avatar?.url && {
      src: `${IMAGE_URL}${user?.avatar?.url}`,
      loading: 'lazy',
    }),
    children: getNameInitials(user.name),
  };
};
