import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { JsonObject } from '../../../types';
import Cropper, { Area } from 'react-easy-crop';
import { Box, Button } from '@mui/material';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { UploadedImage, CroppedImages } from './types';

import http from '../../../services/http-service';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Upload as UploadAntd } from 'antd';
import { DeleteIconStyled } from './styled';
import { createImage, getCroppedImages, getImageUrl } from './image-crop-utils';
import { getUserId } from '../../../common/auth';

const UpdateAvatar: React.FC<JsonObject> = (props: JsonObject) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [uploadedImage, setUploadedImage] = useState<UploadedImage>();
  const [croppedArea, setCroppedArea] = useState<Area>();
  const [croppedImages, setCroppedImages] = useState<CroppedImages>({
    rounded: undefined,
    squared: undefined,
  });
  const [images, setImages] = useState<{ image?: string }>();
  const [error, setError] = useState<string>();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  const areImagesCropped = Object.values(croppedImages).every(Boolean);

  useEffect(() => {
    if (uploadedImage) setError(undefined);

    if (croppedArea && uploadedImage)
      setCroppedImages(getCroppedImages(uploadedImage, croppedArea));
  }, [croppedArea, uploadedImage]);

  useEffect(() => {
    if (areImagesCropped) setImages({ image: croppedImages.squared });
  }, [areImagesCropped, croppedImages, setImages]);

  const onSubmit = async (data: JsonObject) => {
    const toastId = toast.loading('Updating avatar');
    try {
      const userId = await getUserId();
      await http.patch(`users/${userId}/images`, {
        image: images?.image,
      });
      toast.dismiss(toastId);
      setTimeout(() => props.toggleDrawer('avatar', false)({}), 1000);
    } catch (error) {
      toast.update(toastId, {
        render:
          (error as JsonObject)?.response?.data?.error?.message ??
          (error as Error).message,
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      if (uploadedImage) setCroppedArea(croppedAreaPixels);
    },
    [uploadedImage],
  );

  function getValidationError(uploadedFileSize: number, imageWidth: number) {
    let errorType: string | undefined = undefined;

    // 5 MB
    if (uploadedFileSize > 5000000) {
      errorType =
        'The image exceeds size limit. Upload a file with a size lower than 5MB.';
    }
    if (imageWidth < 360) {
      errorType =
        'The image has lower dimensions. Upload a file with a dimension higher than 360 x 360 pixels.';
    }

    return errorType;
  }

  async function validateFile(uploadedFile: UploadedImage) {
    const url = await getImageUrl(uploadedFile);
    const image = await createImage(url);

    const validationError = getValidationError(
      uploadedFile.size as number,
      image.width,
    );

    if (validationError) {
      setError(validationError);
    } else {
      setUploadedImage({ url, image, ...uploadedFile });
    }
  }
  async function onFileChange({ file: uploadedFile }: UploadChangeParam) {
    if (uploadedFile) validateFile(uploadedFile);
  }
  function onTrashIconClick() {
    setUploadedImage(undefined);
    setZoom(1);
  }

  return (
    <Box
      sx={{ width: '100%', maxWidth: 500, marginTop: '40px' }}
      role="presentation"
    >
      <Grid
        container={true}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item={true}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          textAlign="center"
          style={{
            paddingRight: 16,
          }}
        >
          <Typography variant="h5" align="center">
            Update avatar
          </Typography>
        </Grid>
        <Grid
          item={true}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          textAlign="center"
          container={true}
          justifyContent={'space-between'}
          style={{
            height: '256px',
            display: !!uploadedImage ? 'none' : 'block',
            paddingRight: 16,
          }}
        >
          <UploadAntd.Dragger
            accept=".png, .jpg, .jpeg"
            listType="picture"
            fileList={uploadedImage ? [uploadedImage] : []}
            showUploadList={false}
            onChange={onFileChange}
            customRequest={() => null}
            style={{
              ...(!!error && {
                border: `1px dashed #d32f2f`,
              }),
            }}
          >
            {error || 'Click or Drag & drop an image to upload.'}
          </UploadAntd.Dragger>
        </Grid>
        <Grid
          item={true}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          textAlign="center"
          style={{
            paddingRight: 16,
            height: '400px',
            display: !!uploadedImage ? 'block' : 'none',
            position: 'relative',
          }}
        >
          <Cropper
            image={uploadedImage?.url}
            aspect={1}
            crop={crop}
            zoom={zoom}
            cropShape="rect"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            objectFit="vertical-cover"
          />
          <DeleteIconStyled width="32" height="32" onClick={onTrashIconClick} />
        </Grid>
        <Grid
          item={true}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          style={{ paddingRight: 16 }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth={true}
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            Update avatar
          </Button>
        </Grid>
        <Grid
          item={true}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: '16px',
          }}
        >
          <Button
            variant="contained"
            color="info"
            type="submit"
            fullWidth={true}
            onClick={props.toggleDrawer('avatar', false)}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateAvatar;
