import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useLinkBoxStyle } from '../../../common/user';

export default function LinkBox(props: any): JSX.Element {
  const { classes } = useLinkBoxStyle();
  return (
    <Grid
      container={true}
      alignItems="center"
      justifyContent="space-between"
      className={classes.linkBox}
      component={'a'}
      href={props.url}
      target="_blank"
      rel="noreferrer"
    >
      <Grid item={true} lg={1} md={1} sm={2} xs={2}>
        {props.image && (
          <Avatar
            aria-label={props.title}
            src={props.image}
            sx={{ marginLeft: '4px' }}
          />
        )}
      </Grid>
      <Grid item={true} lg={10} md={10} sm={8} xs={8}>
        <Typography variant="body1" align="center">
          {props.title}
        </Typography>
      </Grid>
      <Grid item={true} lg={1} md={1} sm={2} xs={2}></Grid>
    </Grid>
  );
}
