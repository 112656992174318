import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { JsonObject } from '../../../types';
import { Box, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

import { toast } from 'react-toastify';
import { isGoogleProvider } from '../../../common/auth';
import { updatePassword } from 'aws-amplify/auth';

const UpdatePassword: React.FC<JsonObject> = (props: JsonObject) => {
  const [canUpdatePassword, setCanUpdatePassword] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data: JsonObject) => {
    const toastId = toast.loading('Updating password');
    try {
      const { currentPassword, password } = data;
      await updatePassword({
        oldPassword: currentPassword.trim(),
        newPassword: password.trim(),
      });
      toast.dismiss(toastId);
      setTimeout(() => props.toggleDrawer('password', false)({}), 1000);
    } catch (error) {
      toast.update(toastId, {
        render:
          (error as JsonObject)?.response?.data?.error?.message ??
          (error as Error).message,
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    }
  };
  useEffect(() => {
    (async () => {
      const isGoogle = await isGoogleProvider();
      setCanUpdatePassword(!!isGoogle);
    })();
  }, []);
  return (
    <Box
      sx={{ width: '100%', maxWidth: 500, marginTop: '40px', height: '100%' }}
      role="presentation"
    >
      <Grid
        item={true}
        container={true}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item={true} lg={10} md={10} sm={10} xs={10} textAlign="center">
          <Typography variant="h5" align="center">
            Update password
          </Typography>
        </Grid>
        {canUpdatePassword ? (
          <Grid item={true} lg={10} md={10} sm={10} xs={10}>
            <Typography variant="body1" align="center">
              Password update is disabled for Google login
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item={true} lg={10} md={10} sm={10} xs={10}>
              <TextField
                id="current-password"
                label="Current Password"
                margin="dense"
                fullWidth={true}
                type="password"
                error={!!errors?.currentPassword}
                {...register('currentPassword', { required: true })}
              />
            </Grid>
            <Grid item={true} lg={10} md={10} sm={10} xs={10}>
              <TextField
                id="password"
                label="Password"
                margin="dense"
                fullWidth={true}
                type="password"
                error={!!errors?.password}
                {...register('password', { required: true })}
              />
            </Grid>
            <Grid item={true} lg={10} md={10} sm={10} xs={10}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth={true}
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                Update password
              </Button>
            </Grid>
            <Grid
              item={true}
              lg={10}
              md={10}
              sm={10}
              xs={10}
              sx={{
                position: 'absolute',
                width: '100%',
                bottom: '16px',
              }}
            >
              <Button
                variant="contained"
                color="info"
                type="submit"
                fullWidth={true}
                onClick={props.toggleDrawer('password', false)}
              >
                Close
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default UpdatePassword;
