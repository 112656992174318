import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import http from './services/http-service';

const NO_CURRENT_USER = 'No current user';

// eslint-disable-next-line consistent-return
export async function currentAuthSession() {
  try {
    const session = await fetchAuthSession();
    if (session) {
      return session;
    }
  } catch (error) {
    if (error instanceof Error && error.message !== NO_CURRENT_USER) {
      console.log(error);
    }
  }
}

export function bootstrapAuth() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: 'us-east-2_CEbn4eFIs',
        userPoolClientId: '7r5i3h7jdncnnebelujn5j19hu',
        signUpVerificationMethod: 'code',
        loginWith: {
          oauth: {
            domain: 'service-iam-profilelinkbio-002284974481-prod-pool.auth.us-east-2.amazoncognito.com',
            scopes: ['email', 'profile', 'openid'],
            redirectSignIn: ['https://profilelink.bio/u/me'],
            redirectSignOut: ['https://profilelink.bio'],
            // redirectSignIn: ['http://localhost:3000/u/me'],
            // redirectSignOut: ['http://localhost:3000'],
            responseType: 'code',
            providers: ['Google'],
          },
          email: true,
        },
        userAttributes: {
          email: {
            required: true,
          },
        },
      },
    },
  });

  http.addRequestInterceptor(async req => {
    const authSession = await currentAuthSession();

    if (authSession) {
      req.headers.Authorization = `Bearer ${authSession.tokens?.idToken?.toString()}`;
    }

    return req;
  });
}
