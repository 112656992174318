import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import reportWebVitals from './reportWebVitals';
import { bootstrapAuth } from './auth';

import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/base.css';
import './assets/styles/index.css';

const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#f4f5f7',
    },
  },
});

bootstrapAuth();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router />
      <ToastContainer
        hideProgressBar={false}
        newestOnTop={true}
        pauseOnHover={false}
        autoClose={2000}
      />
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
