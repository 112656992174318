import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from 'tss-react/mui';
import React from "react";
import IndexNavComponent from "../../IndexNav";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { schema } from "../../../constants/schema";
import { ERROR_MESSAGE } from "../../../constants/locale";
import { useForm } from "react-hook-form";
import { JsonObject } from "../../../types";
import { toast } from "react-toastify";
import useRedirect from "../../../hooks/use-redirect";
import { confirmResetPassword } from "aws-amplify/auth";

const useStyles = makeStyles()(() => ({
  container: {
    borderTop: "1px solid rgb(229, 227, 221)",
    borderBottom: "1px solid rgb(229, 227, 221)",
    borderLeft: "1px solid rgb(229, 227, 221)",
    borderRight: "1px solid rgb(229, 227, 221)",
    borderRadius: "4px",
    padding: "16px",
  },
}));

const ChangePassword: React.FC<JsonObject> = (props: JsonObject) => {
  const { classes } = useStyles();
  const redirect = useRedirect();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data: JsonObject) => {
    const toastId = toast.loading("Changing password");
    try {
      await confirmResetPassword({
        username:props.email,
        confirmationCode: props.verifyCode,
        newPassword: data.password.trim(),
      });
      toast.update(toastId, {
        render: "Password changed successfully",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      setTimeout(() => redirect("/login"), 2000);
    } catch (error) {
      toast.update(toastId, {
        render: (error as Error).message,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };
  return (
    <React.Fragment>
      <IndexNavComponent />
      <Container maxWidth="xs" className={classes.container}>
        <Grid
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item={true} lg={12} md={12} sm={12} xs={12} textAlign="center">
            <Typography variant="h5">Reset Password</Typography>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="email"
              label="Email"
              margin="dense"
              fullWidth={true}
              type="email"
              defaultValue={props.email}
              disabled={true}
            />
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="password"
              label="Password"
              margin="dense"
              fullWidth={true}
              type="password"
              error={!!errors?.password}
              helperText={!!errors?.password && ERROR_MESSAGE.password}
              {...register("password", { ...schema.password })}
            />
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth={true}
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              Reset Password
            </Button>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item={true} lg={6} md={6} sm={6} xs={6} textAlign="center">
            <NavLink to="/login">
              <Typography variant="body1">Login</Typography>
            </NavLink>
          </Grid>
          <Grid item={true} lg={6} md={6} sm={6} xs={6} textAlign="center">
            <NavLink to="/signup" className="active">
              <Typography variant="body1">Sign up</Typography>
            </NavLink>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default ChangePassword;
