import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import MenuIcon from '@mui/icons-material/Menu';

import { getCurrentUser, signOut } from '@aws-amplify/auth';

import useRedirect from '../../hooks/use-redirect';
import './index.css';

const IndexNav = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const redirect = useRedirect();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const loginLogout = async () => {
    if (userLoggedIn) {
      await signOut();
      setUserLoggedIn(false);
      handleClose();
      redirect('/');
    } else {
      redirect('/login');
    }
  };
  useEffect(() => {
    getCurrentUser()
      .then(() => {
        setUserLoggedIn(true);
      })
      .catch(() => setUserLoggedIn(false));
  });

  return (
    <AppBar position="static" className="appbar" color="transparent">
      <Toolbar className="nav-wrapper">
        <div>
          <NavLink to="/" title="ProfileLinkBio">
            <img
              className="brand-image"
              alt="ProfileLinkBio"
              src="/logo_192_192.png"
              width="45px"
              height="45px"
            />
          </NavLink>
        </div>
        <div>
          {userLoggedIn ? (
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <MenuItem onClick={loginLogout}>Login</MenuItem>
          )}
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => redirect('/u/me')}>Profile</MenuItem>
            <MenuItem onClick={() => redirect('/profile')}>Settings</MenuItem>
            <MenuItem onClick={loginLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default IndexNav;
