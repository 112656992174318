import { CryptoAddressEnum } from '../types';
import { CRYPTO } from './images';

export interface CryptoList {
  id: string;
  name: string;
  symbol: string;
  image: string;
}

export const crypto = {
  [CryptoAddressEnum.BITCOIN_BTC]: {
    name: 'Bitcoin',
    symbol: 'BTC',
    image: CRYPTO.bitcoin_btc,
  },
  [CryptoAddressEnum.BITCOINCASH_BCH]: {
    name: 'BitcoinCash',
    symbol: 'BCH',
    image: CRYPTO.bitcoincash_bch,
  },
  [CryptoAddressEnum.BITCOINSV_BSV]: {
    name: 'Bitcoin SV',
    symbol: 'BSV',
    image: CRYPTO.bitcoinsv_bsv,
  },
  [CryptoAddressEnum.LITECOIN_LTC]: {
    name: 'LiteCoin',
    symbol: 'LTC',
    image: CRYPTO.litecoin_ltc,
  },
  [CryptoAddressEnum.PEERCOIN_PPC]: {
    name: 'PeerCoin',
    symbol: 'PPC',
    image: CRYPTO.peercoin_ppc,
  },
  [CryptoAddressEnum.DOGECOIN_DOGE]: {
    name: 'DogeCoin',
    symbol: 'DOGE',
    image: CRYPTO.dogecoin_doge,
  },
  [CryptoAddressEnum.FREICOIN_FRC]: {
    name: 'FreiCoin',
    symbol: 'FRC',
    image: CRYPTO.freicoin_frc,
  },
  [CryptoAddressEnum.MEGACOIN_MEC]: {
    name: 'MegaCoin',
    symbol: 'MEC',
    image: CRYPTO.megacoin_mec,
  },
  [CryptoAddressEnum.PRIMECOIN_XPM]: {
    name: 'PrimeCoin',
    symbol: 'XPM',
    image: CRYPTO.primecoin_xpm,
  },
  [CryptoAddressEnum.AURORACOIN_AUR]: {
    name: 'AuroraCoin',
    symbol: 'AUR',
    image: CRYPTO.auroracoin_aur,
  },
  [CryptoAddressEnum.NAMECOIN_NMC]: {
    name: 'NameCoin',
    symbol: 'NMC',
    image: CRYPTO.namecoin_nmc,
  },
  [CryptoAddressEnum.BIOCOIN_BIO]: {
    name: 'BioCoin',
    symbol: 'BIO',
    image: CRYPTO.biocoin_bio,
  },
  [CryptoAddressEnum.GARLICOIN_GRLC]: {
    name: 'GarliCoin',
    symbol: 'GRLC',
    image: CRYPTO.garlicoin_grlc,
  },
  [CryptoAddressEnum.VERTCOIN_VTC]: {
    name: 'VertCoin',
    symbol: 'VTC',
    image: CRYPTO.vertcoin_vtc,
  },
  [CryptoAddressEnum.BITCOINGOLD_BTG]: {
    name: 'BitcoinGold',
    symbol: 'BTG',
    image: CRYPTO.bitcoingold_btg,
  },
  [CryptoAddressEnum.KOMODO_KMD]: {
    name: 'Komodo',
    symbol: 'KMD',
    image: CRYPTO.komodo_kmd,
  },
  [CryptoAddressEnum.BITCOINZ_BTCZ]: {
    name: 'BitcoinZ',
    symbol: 'BTCZ',
    image: CRYPTO.bitcoinz_btcz,
  },
  [CryptoAddressEnum.BITCOINPRIVATE_BTCP]: {
    name: 'BitcoinPrivate',
    symbol: 'BTCP',
    image: CRYPTO.bitcoinprivate_btcp,
  },
  [CryptoAddressEnum.HUSH_HUSH]: {
    name: 'Hush',
    symbol: 'HUSH',
    image: CRYPTO.hush_hush,
  },
  [CryptoAddressEnum.ZCASH_ZEC]: {
    name: 'ZCash',
    symbol: 'ZEC',
    image: CRYPTO.zcash_zec,
  },
  [CryptoAddressEnum.ZCLASSIC_ZCL]: {
    name: 'ZClassic',
    symbol: 'ZCL',
    image: CRYPTO.zclassic_zcl,
  },
  [CryptoAddressEnum.VOTECOIN_VOT]: {
    name: 'VoteCoin',
    symbol: 'VOT',
    image: CRYPTO.votecoin_vot,
  },
  [CryptoAddressEnum.DECRED_DCR]: {
    name: 'Decred',
    symbol: 'DCR',
    image: CRYPTO.decred_dcr,
  },
  [CryptoAddressEnum.GAMECREDITS_GAME]: {
    name: 'GameCredits',
    symbol: 'GAME',
    image: CRYPTO.gamecredits_game,
  },
  [CryptoAddressEnum.PIVX_PIVX]: {
    name: 'PIVX',
    symbol: 'PIVX',
    image: CRYPTO.pivx_pivx,
  },
  [CryptoAddressEnum.SOLARCOIN_SLR]: {
    name: 'SolarCoin',
    symbol: 'SLR',
    image: CRYPTO.solarcoin_slr,
  },
  [CryptoAddressEnum.MONACOIN_MONA]: {
    name: 'MonaCoin',
    symbol: 'MONA',
    image: CRYPTO.monacoin_mona,
  },
  [CryptoAddressEnum.DIGIBYTE_DGB]: {
    name: 'DigiByte',
    symbol: 'DGB',
    image: CRYPTO.digibyte_dgb,
  },
  [CryptoAddressEnum.TETHER_USDT]: {
    name: 'Tether',
    symbol: 'USDT',
    image: CRYPTO.tether_usdt,
  },
  [CryptoAddressEnum.RIPPLE_XRP]: {
    name: 'Ripple',
    symbol: 'XRP',
    image: CRYPTO.ripple_xrp,
  },
  [CryptoAddressEnum.DASH_DASH]: {
    name: 'Dash',
    symbol: 'DASH',
    image: CRYPTO.dash_dash,
  },
  [CryptoAddressEnum.NEO_NEO]: {
    name: 'Neo',
    symbol: 'NEO',
    image: CRYPTO.neo_neo,
  },
  [CryptoAddressEnum.NEOGAS_GAS]: {
    name: 'NeoGas',
    symbol: 'GAS',
    image: CRYPTO.neogas_gas,
  },
  [CryptoAddressEnum.QTUM_QTUM]: {
    name: 'Qtum',
    symbol: 'QTUM',
    image: CRYPTO.qtum_qtum,
  },
  [CryptoAddressEnum.WAVES_WAVES]: {
    name: 'Waves',
    symbol: 'WAVES',
    image: CRYPTO.waves_waves,
  },
  [CryptoAddressEnum.ETHEREUM_ETH]: {
    name: 'Ethereum',
    symbol: 'ETH',
    image: CRYPTO.ethereum_eth,
  },
  [CryptoAddressEnum.ETHEREUMPOW_ETHW]: {
    name: 'EthereumPow',
    symbol: 'ETHW',
    image: CRYPTO.ethereumpow_ethw,
  },
  [CryptoAddressEnum.ETHERZERO_ETZ]: {
    name: 'EtherZero',
    symbol: 'ETZ',
    image: CRYPTO.etherzero_etz,
  },
  [CryptoAddressEnum.ETHEREUMCLASSIC_ETC]: {
    name: 'EthereumClassic',
    symbol: 'ETC',
    image: CRYPTO.ethereumclassic_etc,
  },
  [CryptoAddressEnum.CALLISTO_CLO]: {
    name: 'Callisto',
    symbol: 'CLO',
    image: CRYPTO.callisto_clo,
  },
  [CryptoAddressEnum.BANKEX_BKX]: {
    name: 'Bankex',
    symbol: 'BKX',
    image: CRYPTO.bankex_bkx,
  },
  [CryptoAddressEnum.CARDANO_ADA]: {
    name: 'Cardano',
    symbol: 'ADA',
    image: CRYPTO.cardano_ada,
  },
  [CryptoAddressEnum.MONERO_XMR]: {
    name: 'Monero',
    symbol: 'XMR',
    image: CRYPTO.monero_xmr,
  },
  [CryptoAddressEnum.ARAGON_ANT]: {
    name: 'Aragon',
    symbol: 'ANT',
    image: CRYPTO.aragon_ant,
  },
  [CryptoAddressEnum.BASICATTENTIONTOKEN_BAT]: {
    name: 'Basic Attention Token',
    symbol: 'BAT',
    image: CRYPTO.basicattentiontoken_bat,
  },
  [CryptoAddressEnum.BANCOR_BNT]: {
    name: 'Bancor',
    symbol: 'BNT',
    image: CRYPTO.bancor_bnt,
  },
  [CryptoAddressEnum.CIVIC_CVC]: {
    name: 'Civic',
    symbol: 'CVC',
    image: CRYPTO.civic_cvc,
  },
  [CryptoAddressEnum.DISTRICT0X_DNT]: {
    name: 'District0x',
    symbol: 'DNT',
    image: CRYPTO.district0x_dnt,
  },
  [CryptoAddressEnum.GNOSIS_GNO]: {
    name: 'Gnosis',
    symbol: 'GNO',
    image: CRYPTO.gnosis_gno,
  },
  [CryptoAddressEnum.GOLEM_GNT]: {
    name: 'Golem (GNT)',
    symbol: 'GNT',
    image: CRYPTO.golem_gnt,
  },
  [CryptoAddressEnum.GOLEM_GLM]: {
    name: 'Golem',
    symbol: 'GLM',
    image: CRYPTO.golem_glm,
  },
  [CryptoAddressEnum.MATCHPOOL_GUP]: {
    name: 'Matchpool',
    symbol: 'GUP',
    image: CRYPTO.matchpool_gup,
  },
  [CryptoAddressEnum.MELON_MLN]: {
    name: 'Melon',
    symbol: 'MLN',
    image: CRYPTO.melon_mln,
  },
  [CryptoAddressEnum.NUMERAIRE_NMR]: {
    name: 'Numeraire',
    symbol: 'NMR',
    image: CRYPTO.numeraire_nmr,
  },
  [CryptoAddressEnum.OMISEGO_OMG]: {
    name: 'OmiseGO',
    symbol: 'OMG',
    image: CRYPTO.omisego_omg,
  },
  [CryptoAddressEnum.TENX_PAY]: {
    name: 'TenX',
    symbol: 'PAY',
    image: CRYPTO.tenx_pay,
  },
  [CryptoAddressEnum.RIPIOCREDITNETWORK_RCN]: {
    name: 'Ripio Credit Network',
    symbol: 'RCN',
    image: CRYPTO.ripiocreditnetwork_rcn,
  },
  [CryptoAddressEnum.AUGUR_REP]: {
    name: 'Augur',
    symbol: 'REP',
    image: CRYPTO.augur_rep,
  },
  [CryptoAddressEnum.IEXECRLC_RLC]: {
    name: 'iExec RLC',
    symbol: 'RLC',
    image: CRYPTO.iexecrlc_rlc,
  },
  [CryptoAddressEnum.SALT_SALT]: {
    name: 'Salt',
    symbol: 'SALT',
    image: CRYPTO.salt_salt,
  },
  [CryptoAddressEnum.STATUS_SNT]: {
    name: 'Status',
    symbol: 'SNT',
    image: CRYPTO.status_snt,
  },
  [CryptoAddressEnum.STORJ_STORJ]: {
    name: 'Storj',
    symbol: 'STORJ',
    image: CRYPTO.storj_storj,
  },
  [CryptoAddressEnum.SWARMCITY_SWT]: {
    name: 'Swarm City',
    symbol: 'SWT',
    image: CRYPTO.swarmcity_swt,
  },
  [CryptoAddressEnum.TRUEUSD_TUSD]: {
    name: 'TrueUSD',
    symbol: 'TUSD',
    image: CRYPTO.trueusd_tusd,
  },
  [CryptoAddressEnum.WINGS_WINGS]: {
    name: 'Wings',
    symbol: 'WINGS',
    image: CRYPTO.wings_wings,
  },
  [CryptoAddressEnum.ZEROX_ZRX]: {
    name: '0x',
    symbol: 'ZRX',
    image: CRYPTO.zerox_zrx,
  },
  [CryptoAddressEnum.EXPANSE_EXP]: {
    name: 'Expanse',
    symbol: 'EXP',
    image: CRYPTO.expanse_exp,
  },
  [CryptoAddressEnum.VIBERATE_VIB]: {
    name: 'Viberate',
    symbol: 'VIB',
    image: CRYPTO.viberate_vib,
  },
  [CryptoAddressEnum.ODYSSEY_OCN]: {
    name: 'Odyssey',
    symbol: 'OCN',
    image: CRYPTO.odyssey_ocn,
  },
  [CryptoAddressEnum.POLYMATH_POLY]: {
    name: 'Polymath',
    symbol: 'POLY',
    image: CRYPTO.polymath_poly,
  },
  [CryptoAddressEnum.STORM_STORM]: {
    name: 'Storm',
    symbol: 'STORM',
    image: CRYPTO.storm_storm,
  },
  [CryptoAddressEnum.NANO_NANO]: {
    name: 'Nano',
    symbol: 'NANO',
    image: CRYPTO.nano_nano,
  },
  [CryptoAddressEnum.SIACOIN_SC]: {
    name: 'Siacoin',
    symbol: 'SC',
    image: CRYPTO.siacoin_sc,
  },
  [CryptoAddressEnum.LBRYCREDITS_LBC]: {
    name: 'LBRY Credits',
    symbol: 'LBC',
    image: CRYPTO.lbrycredits_lbc,
  },
  [CryptoAddressEnum.TRON_TRX]: {
    name: 'Tron',
    symbol: 'TRX',
    image: CRYPTO.tron_trx,
  },
  [CryptoAddressEnum.NEM_XEM]: {
    name: 'Nem',
    symbol: 'XEM',
    image: CRYPTO.nem_xem,
  },
  [CryptoAddressEnum.LISK_LSK]: {
    name: 'Lisk',
    symbol: 'LSK',
    image: CRYPTO.lisk_lsk,
  },
  [CryptoAddressEnum.STELLAR_XLM]: {
    name: 'Stellar',
    symbol: 'XLM',
    image: CRYPTO.stellar_xlm,
  },
  [CryptoAddressEnum.BTUPROTOCOL_BTU]: {
    name: 'BTU Protocol',
    symbol: 'BTU',
    image: CRYPTO.btuprotocol_btu,
  },
  [CryptoAddressEnum.CRYPTOCOMCOIN_CRO]: {
    name: 'Crypto.com Coin',
    symbol: 'CRO',
    image: CRYPTO.cryptocomcoin_cro,
  },
  [CryptoAddressEnum.MULTICOLLATERALDAI_DAI]: {
    name: 'Multi-collateral DAI',
    symbol: 'DAI',
    image: CRYPTO.multicollateraldai_dai,
  },
  [CryptoAddressEnum.ENJINCOIN_ENJ]: {
    name: 'Enjin Coin',
    symbol: 'ENJ',
    image: CRYPTO.enjincoin_enj,
  },
  [CryptoAddressEnum.HEDGETRADE_HEDG]: {
    name: 'HedgeTrade',
    symbol: 'HEDG',
    image: CRYPTO.hedgetrade_hedg,
  },
  [CryptoAddressEnum.CRED_LBA]: {
    name: 'Cred',
    symbol: 'LBA',
    image: CRYPTO.cred_lba,
  },
  [CryptoAddressEnum.CHAINLINK_LINK]: {
    name: 'Chainlink',
    symbol: 'LINK',
    image: CRYPTO.chainlink_link,
  },
  [CryptoAddressEnum.LOOMNETWORK_LOOM]: {
    name: 'Loom Network',
    symbol: 'LOOM',
    image: CRYPTO.loomnetwork_loom,
  },
  [CryptoAddressEnum.MAKER_MKR]: {
    name: 'Maker',
    symbol: 'MKR',
    image: CRYPTO.maker_mkr,
  },
  [CryptoAddressEnum.METAL_MTL]: {
    name: 'Metal',
    symbol: 'MTL',
    image: CRYPTO.metal_mtl,
  },
  [CryptoAddressEnum.OCEANPROTOCOL_OCEAN]: {
    name: 'Ocean Protocol',
    symbol: 'OCEAN',
    image: CRYPTO.oceanprotocol_ocean,
  },
  [CryptoAddressEnum.QUANT_QNT]: {
    name: 'Quant',
    symbol: 'QNT',
    image: CRYPTO.quant_qnt,
  },
  [CryptoAddressEnum.SYNTHETIXNETWORK_SNX]: {
    name: 'Synthetix Network',
    symbol: 'SNX',
    image: CRYPTO.synthetixnetwork_snx,
  },
  [CryptoAddressEnum.SOLVE_SOLVE]: {
    name: 'SOLVE',
    symbol: 'SOLVE',
    image: CRYPTO.solve_solve,
  },
  [CryptoAddressEnum.SPENDCOIN_SPND]: {
    name: 'Spendcoin',
    symbol: 'SPND',
    image: CRYPTO.spendcoin_spnd,
  },
  [CryptoAddressEnum.TEMCO_TEMCO]: {
    name: 'TEMCO',
    symbol: 'TEMCO',
    image: CRYPTO.temco_temco,
  },
  [CryptoAddressEnum.EOS_EOS]: {
    name: 'EOS',
    symbol: 'EOS',
    image: CRYPTO.eos_eos,
  },
  [CryptoAddressEnum.TEZOS_XTZ]: {
    name: 'Tezos',
    symbol: 'XTZ',
    image: CRYPTO.tezos_xtz,
  },
  [CryptoAddressEnum.VECHAIN_VET]: {
    name: 'VeChain',
    symbol: 'VET',
    image: CRYPTO.vechain_vet,
  },
  [CryptoAddressEnum.STORMX_STMX]: {
    name: 'StormX',
    symbol: 'STMX',
    image: CRYPTO.stormx_stmx,
  },
  [CryptoAddressEnum.AUGURV2_REPV2]: {
    name: 'AugurV2',
    symbol: 'REPV2',
    image: CRYPTO.augurv2_repv2,
  },
  [CryptoAddressEnum.FIRMACHAIN_FCT]: {
    name: 'FirmaChain',
    symbol: 'FCT',
    image: CRYPTO.firmachain_fct,
  },
  [CryptoAddressEnum.BLOCKTRADE_BTT]: {
    name: 'BlockTrade',
    symbol: 'BTT',
    image: CRYPTO.blocktrade_btt,
  },
  [CryptoAddressEnum.QUANTUMRESISTANTLEDGER_QRL]: {
    name: 'Quantum Resistant Ledger',
    symbol: 'QRL',
    image: CRYPTO.quantumresistantledger_qrl,
  },
  [CryptoAddressEnum.TAP_XTP]: {
    name: 'Tap',
    symbol: 'XTP',
    image: CRYPTO.tap_xtp,
  },
  [CryptoAddressEnum.COMPOUND_COMP]: {
    name: 'Compound',
    symbol: 'COMP',
    image: CRYPTO.compound_comp,
  },
  [CryptoAddressEnum.USDCOIN_USDC]: {
    name: 'USD Coin',
    symbol: 'USDC',
    image: CRYPTO.usdcoin_usdc,
  },
  [CryptoAddressEnum.CUSD_CUSD]: {
    name: 'CUSD',
    symbol: 'CUSD',
    image: CRYPTO.cusd_cusd,
  },
  [CryptoAddressEnum.ALGORAND_ALGO]: {
    name: 'Algorand',
    symbol: 'ALGO',
    image: CRYPTO.algorand_algo,
  },
  [CryptoAddressEnum.POLKADOT_DOT]: {
    name: 'Polkadot',
    symbol: 'DOT',
    image: CRYPTO.polkadot_dot,
  },
  [CryptoAddressEnum.UNISWAPCOIN_UNI]: {
    name: 'Uniswap Coin',
    symbol: 'UNI',
    image: CRYPTO.uniswapcoin_uni,
  },
  [CryptoAddressEnum.AAVECOIN_AAVE]: {
    name: 'Aave Coin',
    symbol: 'AAVE',
    image: CRYPTO.aavecoin_aave,
  },
  [CryptoAddressEnum.MATIC_MATIC]: {
    name: 'Matic',
    symbol: 'MATIC',
    image: CRYPTO.matic_matic,
  },
  [CryptoAddressEnum.DECENTRALAND_MANA]: {
    name: 'Decentraland',
    symbol: 'MANA',
    image: CRYPTO.decentraland_mana,
  },
  [CryptoAddressEnum.SOLANA_SOL]: {
    name: 'Solana',
    symbol: 'SOL',
    image: CRYPTO.solana_sol,
  },
  [CryptoAddressEnum.BINANCE_BNB]: {
    name: 'Binance',
    symbol: 'BNB',
    image: CRYPTO.binance_bnb,
  },
  [CryptoAddressEnum.AVALANCHE_AVAX]: {
    name: 'Avalanche',
    symbol: 'AVAX',
    image: CRYPTO.avalanche_avax,
  },
  [CryptoAddressEnum.FLARE_FLR]: {
    name: 'Flare',
    symbol: 'FLR',
    image: CRYPTO.flare_flr,
  },
};

// @ts-ignore
export const cryptoNames: Array<keyof typeof crypto> = Object.keys(crypto);

export const cryptoLists: CryptoList[] = Object.keys(crypto).map(
  cryptoIndex => ({
    // @ts-ignore
    ...crypto[cryptoIndex],
    id: cryptoIndex,
  }),
);
