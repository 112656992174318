import Grid from '@mui/material/Grid';
import http from '../../../services/http-service';
import { toast } from 'react-toastify';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { getUserId } from '../../../common/auth';
import { JsonObject } from '../../../types';
import { schema } from '../../../constants/schema';

export default function CreateLink(props: any): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data: JsonObject) => {
    const toastId = toast.loading('Creating link');
    try {
      const userId = await getUserId();
      const response = await http.post(`users/${userId}/links`, {
        url: data.url.trim(),
      });
      console.log(response);
      await props.loadLinks();
      reset();
      toast.dismiss(toastId);
    } catch (error) {
      toast.update(toastId, {
        render:
          (error as JsonObject)?.response?.data?.error?.message ??
          (error as Error).message,
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  return (
    <Grid container={true} alignItems="center" justifyContent="space-between">
      <Grid item={true} lg={12} md={12} sm={12} xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="url"
            label="Url"
            margin="dense"
            fullWidth={true}
            type="text"
            error={!!errors?.url}
            {...register('url', schema.url)}
            disabled={isSubmitting}
          />
        </form>
      </Grid>
    </Grid>
  );
}
