import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import IndexNav from '../IndexNav';
import { Divider, Drawer, Grid } from '@mui/material';
import UpdatePassword from './UpdatePassword';
import UpdateUsername from './UpdateUsername';
import UpdateName from './UpdateName';
import UpdateAvatar from './UpdateAvatar';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type DrawerType = 'username' | 'name' | 'password' | 'avatar';

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `settings-tab-${index}`,
    'aria-controls': `settings-tabpanel-${index}`,
  };
}

const Profile: React.FC = () => {
  const [state, setState] = useState({
    username: false,
    name: false,
    password: false,
    avatar: false,
  });
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const toggleDrawer =
    (drawerType: DrawerType, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [drawerType]: open });
    };
  return (
    <React.Fragment>
      <IndexNav />
      <Box
        height="100px"
        width="70%"
        marginTop="4px"
        marginBottom="4px"
        margin="auto"
        boxShadow={0}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            centered={true}
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Security" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid
            container={true}
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item={true} lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="body1"
                onClick={toggleDrawer('username', true)}
              >
                Update username
              </Typography>
            </Grid>
            <Grid item={true} lg={12} md={12} sm={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item={true} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="body1" onClick={toggleDrawer('name', true)}>
                Update name
              </Typography>
            </Grid>
            <Grid item={true} lg={12} md={12} sm={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item={true} lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="body1"
                onClick={toggleDrawer('avatar', true)}
              >
                Update avatar
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid
            container={true}
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item={true} lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="body1"
                onClick={toggleDrawer('password', true)}
              >
                Update password
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
      <Drawer
        open={state['username']}
        anchor="right"
        onClose={toggleDrawer('username', false)}
      >
        <UpdateUsername toggleDrawer={toggleDrawer} />
      </Drawer>
      <Drawer
        open={state['name']}
        anchor="right"
        onClose={toggleDrawer('name', false)}
      >
        <UpdateName toggleDrawer={toggleDrawer} />
      </Drawer>
      <Drawer
        open={state['password']}
        anchor="right"
        onClose={toggleDrawer('password', false)}
      >
        <UpdatePassword toggleDrawer={toggleDrawer} />
      </Drawer>
      <Drawer
        open={state['avatar']}
        anchor="right"
        onClose={toggleDrawer('avatar', false)}
      >
        <UpdateAvatar toggleDrawer={toggleDrawer} />
      </Drawer>
    </React.Fragment>
  );
};

export default Profile;
