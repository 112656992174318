import { useLocation } from 'react-router-dom';

function fixEmailWithPlus(email: string | null) {
  return email && email.replace(/\s/g, '+');
}

function useSignUpParameters(): {
  email?: string;
  verifyCode?: string;
} {
  const queryParams = new URLSearchParams(useLocation().search);

  const verifyCode = queryParams.get('verifyCode') || undefined;
  const email = fixEmailWithPlus(queryParams.get('email')) || undefined;

  return {
    email,
    verifyCode,
  };
}

export default useSignUpParameters;
