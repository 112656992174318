import { Box, Divider, Grid, Typography } from '@mui/material';

import { NavLink } from 'react-router-dom';
import React from 'react';

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f4f5f7',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        padding: '16px',
      }}
    >
      <Grid
        container={true}
        spacing={2}
        alignItems="flex-start"
        justifyContent="center"
        direction="row"
      >
        <Grid
          item={true}
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={6}
        >
          <Grid item={true}>
            <Typography variant="h5">ProfileLink.Bio</Typography>
          </Grid>
          <Grid item={true}>
            <Divider variant="inset" />
          </Grid>
          <Grid item={true}>
            <NavLink to="/login">
              <Typography variant="subtitle1">Login</Typography>
            </NavLink>
          </Grid>
          <Grid item={true}>
            <NavLink to="/signup">
              <Typography variant="subtitle1">Sign up</Typography>
            </NavLink>
          </Grid>
        </Grid>
        <Grid
          item={true}
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={6}
        >
          <Grid item={true}>
            <Typography variant="h5">Resources</Typography>
          </Grid>
          <Grid item={true}>
            <Divider variant="inset" />
          </Grid>
          <Grid item={true}>
            <NavLink to="mailto:hello@profilelink.bio">
              <Typography variant="subtitle1">Contact</Typography>
            </NavLink>
          </Grid>
        </Grid>
        <Grid
          item={true}
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={6}
        >
          <Grid item={true}>
            <Typography variant="h5">Legal</Typography>
          </Grid>
          <Grid item={true}>
            <Divider variant="inset" />
          </Grid>
          <Grid item={true}>
            <NavLink to="/privacy">
              <Typography variant="subtitle1">Terms of service</Typography>
            </NavLink>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
