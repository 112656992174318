import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { JsonObject } from '../../../types';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useCryptoBoxStyles } from '../../../common/user';
import { CryptoList, cryptoLists } from '../../../constants/crypto';
import http from '../../../services/http-service';
import { toast } from 'react-toastify';
import { getUserId } from '../../../common/auth';

const CreateCryptoAddress: React.FC<JsonObject> = (props: JsonObject) => {
  const { classes } = useCryptoBoxStyles();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      address: '',
      crypto: null,
    },
  });

  const onSubmit = async (data: JsonObject) => {
    const toastId = toast.loading('Creating crypto address');
    try {
      const userId = await getUserId();
      await http.post(`users/${userId}/crypto-addresses`, {
        address: data.address.trim(),
        name: data.crypto.id,
      });
      await props.loadCrypto();
      reset();
      toast.dismiss(toastId);
    } catch (error) {
      toast.update(toastId, {
        render:
          (error as JsonObject)?.response?.data?.error?.message ??
          (error as Error).message,
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    }
  };
  return (
    <Card className={classes.root}>
      <CardActions className={classes.header}>
        <Grid
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item={true}>
            <Typography variant="body1" align="center">
              Crypto Address
            </Typography>
          </Grid>
        </Grid>
      </CardActions>
      <CardContent className={classes.center}>
        <Grid container={true} spacing={2} alignItems="center">
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Controller
              control={control}
              name="crypto"
              rules={{
                required: true,
              }}
              render={({ field: { ref, onChange, value, ...fields } }) => (
                <Autocomplete
                  id="crypto-select"
                  fullWidth={true}
                  options={cryptoLists}
                  autoHighlight={true}
                  // @ts-ignore
                  onChange={(event: any, newCryptoValue: CryptoList) => {
                    // @ts-ignore
                    onChange(newCryptoValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={value}
                  getOptionLabel={option => option.name}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={option.image}
                        alt={option.name}
                      />
                      {option.name} ({option.symbol})
                    </Box>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      {...fields}
                      inputRef={ref}
                      label="Choose a crypto"
                      error={!!errors?.crypto}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="address"
              label="Crypto Address"
              margin="dense"
              fullWidth={true}
              type="text"
              error={!!errors?.address}
              {...register('address', { required: true })}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.footer}>
        <Grid
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item={true} lg={9} md={9} sm={9} xs={9}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth={true}
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default CreateCryptoAddress;
