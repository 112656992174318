import { fetchAuthSession } from 'aws-amplify/auth';
import { JsonObject } from '../types';

export const getUserId = async (): Promise<string> => {
  const user = await fetchAuthSession();

  return user.tokens?.idToken?.payload.userId as string;
};

export const getUserName = async (): Promise<string> => {
  const user = await fetchAuthSession();

  return user.tokens?.idToken?.payload.name as string;
};

export const getUserUsername = async (): Promise<string> => {
  const user = await fetchAuthSession();

  return user.tokens?.idToken?.payload.username as string;
};

export const getUserAvatar = async (): Promise<JsonObject | undefined> => {
  const user = await fetchAuthSession();
  const avatar = user.tokens?.idToken?.payload.avatar;

  return avatar && JSON.parse(avatar as string);
};

export const isGoogleProvider = async (): Promise<boolean> => {
  const user = await fetchAuthSession();
  const identities = user.tokens?.idToken?.payload.identities as JsonObject;

  return (
    !!identities &&
    identities.length !== 0 &&
    identities[0].providerName === 'Google'
  );
};

export const getUser = async (): Promise<{
  userId: string;
  name: string;
  username: string;
  avatar: any;
}> => {
  const user = await fetchAuthSession();
  const { userId, name, username, avatar } =
    user.tokens?.idToken?.payload || {};

  return {
    userId: userId as string,
    name: name as string,
    username: username as string,
    avatar: avatar && JSON.parse(avatar as string),
  };
};
