import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import { confirmSignUp } from 'aws-amplify/auth';
import React, { useEffect, useState } from 'react';
import IndexNavComponent from '../IndexNav';
import { Divider, Grid, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import useSignUpParameters from '../../hooks/use-sign-up-parameters';

const useStyles = makeStyles()(() => ({
  container: {
    borderTop: '1px solid rgb(229, 227, 221)',
    borderBottom: '1px solid rgb(229, 227, 221)',
    borderLeft: '1px solid rgb(229, 227, 221)',
    borderRight: '1px solid rgb(229, 227, 221)',
    borderRadius: '4px',
    padding: '16px',
  },
}));

export const alreadyConfirmedMessage =
  'User cannot be confirmed. Current status is CONFIRMED';

const VerifyEmail: React.FC = () => {
  const { classes } = useStyles();
  const [verified, setVerified] = useState(false);
  const { verifyCode, email } = useSignUpParameters();

  useEffect(() => {
    (async () => {
      try {
        if (email && verifyCode) {
          await confirmSignUp({
            username: email,
            confirmationCode: verifyCode,
          });
          setVerified(true);
        }
      } catch (err) {
        if (err instanceof Error && err.message === alreadyConfirmedMessage) {
          setVerified(true);
          return;
        }

        setVerified(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <IndexNavComponent />
      <Container maxWidth="xs" className={classes.container}>
        <Grid
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item={true} lg={12} md={12} sm={12} xs={12} textAlign="center">
            <Typography variant="h5">Verify Email</Typography>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12} textAlign="center">
            {verified ? (
              <Typography variant="body1">
                Your email has been verified and you can now{' '}
                <NavLink to="/login" className="active">
                  login.
                </NavLink>
              </Typography>
            ) : (
              <Typography variant="body1">
                Your email is being verified...
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default VerifyEmail;
