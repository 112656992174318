import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { JsonObject } from '../../../types';
import { Box, Button, TextField } from '@mui/material';

import http from '../../../services/http-service';
import { toast } from 'react-toastify';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { useForm } from 'react-hook-form';
import { getUserId, getUserName } from '../../../common/auth';

const UpdateName: React.FC<JsonObject> = (props: JsonObject) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data: JsonObject) => {
    const toastId = toast.loading('Updating name');
    try {
      const userId = await getUserId();
      await http.patch(`users/${userId}`, {
        name: data.name.trim(),
      });
      toast.dismiss(toastId);
      await getCurrentUser();
      setTimeout(() => props.toggleDrawer('name', false)({}), 1000);
    } catch (error) {
      toast.update(toastId, {
        render:
          (error as JsonObject)?.response?.data?.error?.message ??
          (error as Error).message,
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    }
  };
  useEffect(() => {
    fetchAuthSession().then(user => {
      setValue('name', user.tokens?.idToken?.payload.name);
    });
    getUserName().then(name => setValue('name', name));
  }, [setValue]);
  return (
    <Box
      sx={{ width: '100%', maxWidth: 500, marginTop: '40px' }}
      role="presentation"
    >
      <Grid
        container={true}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item={true} lg={10} md={10} sm={10} xs={10} textAlign="center">
          <Typography variant="h5" align="center">
            Update name
          </Typography>
        </Grid>
        <Grid item={true} lg={10} md={10} sm={10} xs={10}>
          <TextField
            id="name"
            label="Name"
            margin="dense"
            type="text"
            fullWidth={true}
            error={!!errors?.name}
            {...register('name', { required: true })}
          />
        </Grid>
        <Grid item={true} lg={10} md={10} sm={10} xs={10}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth={true}
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            Update name
          </Button>
        </Grid>
        <Grid
          item={true}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: '16px',
          }}
        >
          <Button
            variant="contained"
            color="info"
            type="submit"
            fullWidth={true}
            onClick={props.toggleDrawer('name', false)}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateName;
