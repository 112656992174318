import { BaseDynamoObject } from './shared';

export enum CryptoAddressEnum {
  BITCOIN_BTC = 'bitcoin_btc',
  BITCOINCASH_BCH = 'bitcoincash_bch',
  BITCOINSV_BSV = 'bitcoinsv_bsv',
  LITECOIN_LTC = 'litecoin_ltc',
  PEERCOIN_PPC = 'peercoin_ppc',
  DOGECOIN_DOGE = 'dogecoin_doge',
  FREICOIN_FRC = 'freicoin_frc',
  MEGACOIN_MEC = 'megacoin_mec',
  PRIMECOIN_XPM = 'primecoin_xpm',
  AURORACOIN_AUR = 'auroracoin_aur',
  NAMECOIN_NMC = 'namecoin_nmc',
  BIOCOIN_BIO = 'biocoin_bio',
  GARLICOIN_GRLC = 'garlicoin_grlc',
  VERTCOIN_VTC = 'vertcoin_vtc',
  BITCOINGOLD_BTG = 'bitcoingold_btg',
  KOMODO_KMD = 'komodo_kmd',
  BITCOINZ_BTCZ = 'bitcoinz_btcz',
  BITCOINPRIVATE_BTCP = 'bitcoinprivate_btcp',
  HUSH_HUSH = 'hush_hush',
  ZCASH_ZEC = 'zcash_zec',
  ZCLASSIC_ZCL = 'zclassic_zcl',
  VOTECOIN_VOT = 'votecoin_vot',
  DECRED_DCR = 'decred_dcr',
  GAMECREDITS_GAME = 'gamecredits_game',
  PIVX_PIVX = 'pivx_pivx',
  SOLARCOIN_SLR = 'solarcoin_slr',
  MONACOIN_MONA = 'monacoin_mona',
  DIGIBYTE_DGB = 'digibyte_dgb',
  TETHER_USDT = 'tether_usdt',
  RIPPLE_XRP = 'ripple_xrp',
  DASH_DASH = 'dash_dash',
  NEO_NEO = 'neo_neo',
  NEOGAS_GAS = 'neogas_gas',
  QTUM_QTUM = 'qtum_qtum',
  WAVES_WAVES = 'waves_waves',
  ETHEREUM_ETH = 'ethereum_eth',
  ETHEREUMPOW_ETHW = 'ethereumpow_ethw',
  ETHERZERO_ETZ = 'etherzero_etz',
  ETHEREUMCLASSIC_ETC = 'ethereumclassic_etc',
  CALLISTO_CLO = 'callisto_clo',
  BANKEX_BKX = 'bankex_bkx',
  CARDANO_ADA = 'cardano_ada',
  MONERO_XMR = 'monero_xmr',
  ARAGON_ANT = 'aragon_ant',
  BASICATTENTIONTOKEN_BAT = 'basicattentiontoken_bat',
  BANCOR_BNT = 'bancor_bnt',
  CIVIC_CVC = 'civic_cvc',
  DISTRICT0X_DNT = 'district0x_dnt',
  GNOSIS_GNO = 'gnosis_gno',
  GOLEM_GNT = 'golem_gnt',
  GOLEM_GLM = 'golem_glm',
  MATCHPOOL_GUP = 'matchpool_gup',
  MELON_MLN = 'melon_mln',
  NUMERAIRE_NMR = 'numeraire_nmr',
  OMISEGO_OMG = 'omisego_omg',
  TENX_PAY = 'tenx_pay',
  RIPIOCREDITNETWORK_RCN = 'ripiocreditnetwork_rcn',
  AUGUR_REP = 'augur_rep',
  IEXECRLC_RLC = 'iexecrlc_rlc',
  SALT_SALT = 'salt_salt',
  STATUS_SNT = 'status_snt',
  STORJ_STORJ = 'storj_storj',
  SWARMCITY_SWT = 'swarmcity_swt',
  TRUEUSD_TUSD = 'trueusd_tusd',
  WINGS_WINGS = 'wings_wings',
  ZEROX_ZRX = '0x_zrx',
  EXPANSE_EXP = 'expanse_exp',
  VIBERATE_VIB = 'viberate_vib',
  ODYSSEY_OCN = 'odyssey_ocn',
  POLYMATH_POLY = 'polymath_poly',
  STORM_STORM = 'storm_storm',
  NANO_NANO = 'nano_nano',
  SIACOIN_SC = 'siacoin_sc',
  LBRYCREDITS_LBC = 'lbrycredits_lbc',
  TRON_TRX = 'tron_trx',
  NEM_XEM = 'nem_xem',
  LISK_LSK = 'lisk_lsk',
  STELLAR_XLM = 'stellar_xlm',
  BTUPROTOCOL_BTU = 'btuprotocol_btu',
  CRYPTOCOMCOIN_CRO = 'cryptocomcoin_cro',
  MULTICOLLATERALDAI_DAI = 'multicollateraldai_dai',
  ENJINCOIN_ENJ = 'enjincoin_enj',
  HEDGETRADE_HEDG = 'hedgetrade_hedg',
  CRED_LBA = 'cred_lba',
  CHAINLINK_LINK = 'chainlink_link',
  LOOMNETWORK_LOOM = 'loomnetwork_loom',
  MAKER_MKR = 'maker_mkr',
  METAL_MTL = 'metal_mtl',
  OCEANPROTOCOL_OCEAN = 'oceanprotocol_ocean',
  QUANT_QNT = 'quant_qnt',
  SYNTHETIXNETWORK_SNX = 'synthetixnetwork_snx',
  SOLVE_SOLVE = 'solve_solve',
  SPENDCOIN_SPND = 'spendcoin_spnd',
  TEMCO_TEMCO = 'temco_temco',
  EOS_EOS = 'eos_eos',
  TEZOS_XTZ = 'tezos_xtz',
  VECHAIN_VET = 'vechain_vet',
  STORMX_STMX = 'stormx_stmx',
  AUGURV2_REPV2 = 'augurv2_repv2',
  FIRMACHAIN_FCT = 'firmachain_fct',
  BLOCKTRADE_BTT = 'blocktrade_btt',
  QUANTUMRESISTANTLEDGER_QRL = 'quantumresistantledger_qrl',
  TAP_XTP = 'tap_xtp',
  COMPOUND_COMP = 'compound_comp',
  USDCOIN_USDC = 'usdcoin_usdc',
  CUSD_CUSD = 'cusd_cusd',
  ALGORAND_ALGO = 'algorand_algo',
  POLKADOT_DOT = 'polkadot_dot',
  UNISWAPCOIN_UNI = 'uniswapcoin_uni',
  AAVECOIN_AAVE = 'aavecoin_aave',
  MATIC_MATIC = 'matic_matic',
  DECENTRALAND_MANA = 'decentraland_mana',
  SOLANA_SOL = 'solana_sol',
  BINANCE_BNB = 'binance_bnb',
  AVALANCHE_AVAX = 'avalanche_avax',
  FLARE_FLR = 'flare_flr',
}

export interface CryptoAddress extends BaseDynamoObject {
  userId: string;
  address: string;
  name: CryptoAddressEnum;
}
