import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

import { JsonObject } from '../../../types';
import http from '../../../services/http-service';
import { toast } from 'react-toastify';
import { getCurrentUser } from 'aws-amplify/auth';
import { getUserId, getUserUsername } from '../../../common/auth';

const UpdateUsername: React.FC<JsonObject> = (props: JsonObject) => {
  const [username, setUsername] = useState<string>('');
  const [usernameValid, setUsernameValid] = useState(true);
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [usernameExistsMessage, setUsernameExistsMessage] = useState(
    'Type username to check for availability',
  );

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { isSubmitting, errors },
    setValue,
  } = useForm({
    mode: 'onChange',
  });
  watch();

  const onSubmit = async (data: JsonObject) => {
    if (usernameAvailable) {
      const toastId = toast.loading('Updating username');
      try {
        const userId = await getUserId();
        await http.patch(`users/${userId}/usernames`, {
          username: data.username.trim(),
        });
        toast.dismiss(toastId);
        await getCurrentUser();
        setTimeout(() => props.toggleDrawer('username', false)({}), 1000);
      } catch (error) {
        toast.update(toastId, {
          render:
            (error as JsonObject)?.response?.data?.error?.message ??
            (error as Error).message,
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        });
      }
    }
  };
  const checkUsername = useCallback(
    async (username: string) => {
      if (username.length !== 0 && !errors?.username) {
        const result = await http.post('usernames/availability', {
          username: username.trim(),
        });
        if (result.data.items.length > 0) {
          if (result.data.items[0].exists) setError('username', {});
          setUsernameAvailable(!result.data.items[0].exists);
          setUsernameExistsMessage(
            `The username "${username}" is ${
              result.data.items[0].exists ? 'already taken' : 'available'
            }`,
          );
        }
      }
    },
    [errors, setError],
  );
  useEffect(() => {
    const timeoutId = setTimeout(() => checkUsername(username), 500);
    return () => clearTimeout(timeoutId);
  }, [username, checkUsername]);
  useEffect(() => {
    const subscription = watch(value => {
      setUsername(value.username);
      setUsernameValid(new RegExp(/^[a-zA-Z0-9_]{1,20}$/).test(value.username));
    });
    return () => subscription.unsubscribe();
  }, [watch, checkUsername]);
  useEffect(() => {
    getUserUsername().then(username => setValue('username', username));
  }, [setValue]);
  return (
    <Box
      sx={{ width: '100%', maxWidth: 500, marginTop: '40px' }}
      role="presentation"
    >
      <Grid
        container={true}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item={true} lg={10} md={10} sm={10} xs={10} textAlign="center">
          <Typography variant="h5" align="center">
            Update username
          </Typography>
        </Grid>
        <Grid item={true} lg={10} md={10} sm={10} xs={10}>
          <TextField
            id="username"
            label="Username"
            margin="dense"
            fullWidth={true}
            type="text"
            error={!!errors?.username}
            helperText={
              !usernameValid &&
              'A username cannot be longer than 20 characters and can only contain alphanumeric characters (letters A-Z, numbers 0-9) with the exception of underscores.'
            }
            {...register('username', {
              required: true,
              pattern: /^[a-zA-Z0-9_]{1,20}$/,
            })}
          />
        </Grid>
        <Grid item={true} lg={10} md={10} sm={10} xs={10}>
          <Typography variant="body1">{usernameExistsMessage}</Typography>
        </Grid>
        <Grid item={true} lg={10} md={10} sm={10} xs={10}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth={true}
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            Update username
          </Button>
        </Grid>
        <Grid
          item={true}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: '16px',
          }}
        >
          <Button
            variant="contained"
            color="info"
            type="submit"
            fullWidth={true}
            onClick={props.toggleDrawer('username', false)}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateUsername;
