import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import { useCryptoBoxStyles } from '../../../common/user';
import { JsonObject } from '../../../types';

function MenuList(props: JsonObject): JSX.Element {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const copyAddress = () => {
    handleClose();
    navigator.clipboard.writeText(props.address);
  };
  return (
    <React.Fragment>
      <IconButton aria-label="settings" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="settings"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          disableRipple={true}
          disableTouchRipple={true}
          onClick={copyAddress}
        >
          <ListItemIcon>
            <ContentCopyIcon />
          </ListItemIcon>
          <ListItemText inset={false} primary={'Copy address'} />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default function CryptoAddressBox(props: any): JSX.Element {
  const { classes } = useCryptoBoxStyles();
  return (
    <Card className={classes.root}>
      <CardActions className={classes.header}>
        <Grid
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item={true} lg={3} md={3} sm={3} xs={3}>
            <Avatar
              aria-label={props.coin.name}
              src={props.coin.image}
              sx={{ marginLeft: '4px' }}
            />
          </Grid>
          <Grid item={true} lg={6} md={6} sm={6} xs={6}>
            <Typography variant="body1" align="center">
              {props.coin.name}
            </Typography>
          </Grid>
          <Grid
            item={true}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            style={{ display: 'block' }}
          >
            {MenuList({ address: props.address })}
          </Grid>
        </Grid>
      </CardActions>
      <CardContent className={classes.center}>
        <QRCode value={props.address} size={160} level="L" />
      </CardContent>
      <CardActions className={classes.footer}>
        <Grid
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item={true} lg={9} md={9} sm={9} xs={9}>
            <Typography variant="body2" className={classes.address}>
              {props.address}
            </Typography>
          </Grid>
          <Grid item={true} lg={3} md={3} sm={3} xs={3}>
            <IconButton
              aria-label="copy"
              onClick={() => navigator.clipboard.writeText(props.address)}
            >
              <ContentCopyIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
