/// <reference path='./images.d.ts'/>

// Crypto
import bitcoin_btc from '../assets/images/crypto/bitcoin_btc.png';
import bitcoincash_bch from '../assets/images/crypto/bitcoincash_bch.png';
import bitcoinsv_bsv from '../assets/images/crypto/bitcoinsv_bsv.png';
import litecoin_ltc from '../assets/images/crypto/litecoin_ltc.png';
import peercoin_ppc from '../assets/images/crypto/peercoin_ppc.png';
import dogecoin_doge from '../assets/images/crypto/dogecoin_doge.png';
import freicoin_frc from '../assets/images/crypto/freicoin_frc.png';
import megacoin_mec from '../assets/images/crypto/megacoin_mec.png';
import primecoin_xpm from '../assets/images/crypto/primecoin_xpm.png';
import auroracoin_aur from '../assets/images/crypto/auroracoin_aur.png';
import namecoin_nmc from '../assets/images/crypto/namecoin_nmc.png';
import biocoin_bio from '../assets/images/crypto/biocoin_bio.png';
import garlicoin_grlc from '../assets/images/crypto/garlicoin_grlc.png';
import vertcoin_vtc from '../assets/images/crypto/vertcoin_vtc.png';
import bitcoingold_btg from '../assets/images/crypto/bitcoingold_btg.png';
import komodo_kmd from '../assets/images/crypto/komodo_kmd.png';
import bitcoinz_btcz from '../assets/images/crypto/bitcoinz_btcz.png';
import bitcoinprivate_btcp from '../assets/images/crypto/bitcoinprivate_btcp.png';
import hush_hush from '../assets/images/crypto/hush_hush.png';
import zcash_zec from '../assets/images/crypto/zcash_zec.png';
import zclassic_zcl from '../assets/images/crypto/zclassic_zcl.png';
import votecoin_vot from '../assets/images/crypto/votecoin_vot.png';
import decred_dcr from '../assets/images/crypto/decred_dcr.png';
import gamecredits_game from '../assets/images/crypto/gamecredits_game.png';
import pivx_pivx from '../assets/images/crypto/pivx_pivx.png';
import solarcoin_slr from '../assets/images/crypto/solarcoin_slr.png';
import monacoin_mona from '../assets/images/crypto/monacoin_mona.png';
import digibyte_dgb from '../assets/images/crypto/digibyte_dgb.png';
import tether_usdt from '../assets/images/crypto/tether_usdt.png';
import ripple_xrp from '../assets/images/crypto/ripple_xrp.png';
import dash_dash from '../assets/images/crypto/dash_dash.png';
import neo_neo from '../assets/images/crypto/neo_neo.png';
import neogas_gas from '../assets/images/crypto/neogas_gas.png';
import qtum_qtum from '../assets/images/crypto/qtum_qtum.png';
import waves_waves from '../assets/images/crypto/waves_waves.png';
import ethereum_eth from '../assets/images/crypto/ethereum_eth.png';
import ethereumpow_ethw from '../assets/images/crypto/ethereumpow_ethw.png';
import etherzero_etz from '../assets/images/crypto/etherzero_etz.png';
import ethereumclassic_etc from '../assets/images/crypto/ethereumclassic_etc.png';
import callisto_clo from '../assets/images/crypto/callisto_clo.png';
import bankex_bkx from '../assets/images/crypto/bankex_bkx.png';
import cardano_ada from '../assets/images/crypto/cardano_ada.png';
import monero_xmr from '../assets/images/crypto/monero_xmr.png';
import aragon_ant from '../assets/images/crypto/aragon_ant.png';
import basicattentiontoken_bat from '../assets/images/crypto/basicattentiontoken_bat.png';
import bancor_bnt from '../assets/images/crypto/bancor_bnt.png';
import civic_cvc from '../assets/images/crypto/civic_cvc.png';
import district0x_dnt from '../assets/images/crypto/district0x_dnt.png';
import gnosis_gno from '../assets/images/crypto/gnosis_gno.png';
import golem_gnt from '../assets/images/crypto/golem_gnt.png';
import golem_glm from '../assets/images/crypto/golem_glm.png';
import matchpool_gup from '../assets/images/crypto/matchpool_gup.png';
import melon_mln from '../assets/images/crypto/melon_mln.png';
import numeraire_nmr from '../assets/images/crypto/numeraire_nmr.png';
import omisego_omg from '../assets/images/crypto/omisego_omg.png';
import tenx_pay from '../assets/images/crypto/tenx_pay.png';
import ripiocreditnetwork_rcn from '../assets/images/crypto/ripiocreditnetwork_rcn.png';
import augur_rep from '../assets/images/crypto/augur_rep.png';
import iexecrlc_rlc from '../assets/images/crypto/iexecrlc_rlc.png';
import salt_salt from '../assets/images/crypto/salt_salt.png';
import status_snt from '../assets/images/crypto/status_snt.png';
import storj_storj from '../assets/images/crypto/storj_storj.png';
import swarmcity_swt from '../assets/images/crypto/swarmcity_swt.png';
import trueusd_tusd from '../assets/images/crypto/trueusd_tusd.png';
import wings_wings from '../assets/images/crypto/wings_wings.png';
import zerox_zrx from '../assets/images/crypto/zerox_zrx.png';
import expanse_exp from '../assets/images/crypto/expanse_exp.png';
import viberate_vib from '../assets/images/crypto/viberate_vib.png';
import odyssey_ocn from '../assets/images/crypto/odyssey_ocn.png';
import polymath_poly from '../assets/images/crypto/polymath_poly.png';
import storm_storm from '../assets/images/crypto/storm_storm.png';
import nano_nano from '../assets/images/crypto/nano_nano.png';
import siacoin_sc from '../assets/images/crypto/siacoin_sc.png';
import lbrycredits_lbc from '../assets/images/crypto/lbrycredits_lbc.png';
import tron_trx from '../assets/images/crypto/tron_trx.png';
import nem_xem from '../assets/images/crypto/nem_xem.png';
import lisk_lsk from '../assets/images/crypto/lisk_lsk.png';
import stellar_xlm from '../assets/images/crypto/stellar_xlm.png';
import btuprotocol_btu from '../assets/images/crypto/btuprotocol_btu.png';
import cryptocomcoin_cro from '../assets/images/crypto/cryptocomcoin_cro.png';
import multicollateraldai_dai from '../assets/images/crypto/multicollateraldai_dai.png';
import enjincoin_enj from '../assets/images/crypto/enjincoin_enj.png';
import hedgetrade_hedg from '../assets/images/crypto/hedgetrade_hedg.png';
import cred_lba from '../assets/images/crypto/cred_lba.png';
import chainlink_link from '../assets/images/crypto/chainlink_link.png';
import loomnetwork_loom from '../assets/images/crypto/loomnetwork_loom.png';
import maker_mkr from '../assets/images/crypto/maker_mkr.png';
import metal_mtl from '../assets/images/crypto/metal_mtl.png';
import oceanprotocol_ocean from '../assets/images/crypto/oceanprotocol_ocean.png';
import quant_qnt from '../assets/images/crypto/quant_qnt.png';
import synthetixnetwork_snx from '../assets/images/crypto/synthetixnetwork_snx.png';
import solve_solve from '../assets/images/crypto/solve_solve.png';
import spendcoin_spnd from '../assets/images/crypto/spendcoin_spnd.png';
import temco_temco from '../assets/images/crypto/temco_temco.png';
import eos_eos from '../assets/images/crypto/eos_eos.png';
import tezos_xtz from '../assets/images/crypto/tezos_xtz.png';
import vechain_vet from '../assets/images/crypto/vechain_vet.png';
import stormx_stmx from '../assets/images/crypto/stormx_stmx.png';
import augurv2_repv2 from '../assets/images/crypto/augurv2_repv2.png';
import firmachain_fct from '../assets/images/crypto/firmachain_fct.png';
import blocktrade_btt from '../assets/images/crypto/blocktrade_btt.png';
import quantumresistantledger_qrl from '../assets/images/crypto/quantumresistantledger_qrl.png';
import tap_xtp from '../assets/images/crypto/tap_xtp.png';
import compound_comp from '../assets/images/crypto/compound_comp.png';
import usdcoin_usdc from '../assets/images/crypto/usdcoin_usdc.png';
import cusd_cusd from '../assets/images/crypto/cusd_cusd.png';
import algorand_algo from '../assets/images/crypto/algorand_algo.png';
import polkadot_dot from '../assets/images/crypto/polkadot_dot.png';
import uniswapcoin_uni from '../assets/images/crypto/uniswapcoin_uni.png';
import aavecoin_aave from '../assets/images/crypto/aavecoin_aave.png';
import matic_matic from '../assets/images/crypto/matic_matic.png';
import decentraland_mana from '../assets/images/crypto/decentraland_mana.png';
import solana_sol from '../assets/images/crypto/solana_sol.png';
import binance_bnb from '../assets/images/crypto/binance_bnb.png';
import avalanche_avax from '../assets/images/crypto/avalanche_avax.png';
import flare_flr from '../assets/images/crypto/flare_flr.png';

// Icons
import donate from '../assets/images/donate.png';

export const CRYPTO = {
  bitcoin_btc,
  bitcoincash_bch,
  bitcoinsv_bsv,
  litecoin_ltc,
  peercoin_ppc,
  dogecoin_doge,
  freicoin_frc,
  megacoin_mec,
  primecoin_xpm,
  auroracoin_aur,
  namecoin_nmc,
  biocoin_bio,
  garlicoin_grlc,
  vertcoin_vtc,
  bitcoingold_btg,
  komodo_kmd,
  bitcoinz_btcz,
  bitcoinprivate_btcp,
  hush_hush,
  zcash_zec,
  zclassic_zcl,
  votecoin_vot,
  decred_dcr,
  gamecredits_game,
  pivx_pivx,
  solarcoin_slr,
  monacoin_mona,
  digibyte_dgb,
  tether_usdt,
  ripple_xrp,
  dash_dash,
  neo_neo,
  neogas_gas,
  qtum_qtum,
  waves_waves,
  ethereum_eth,
  ethereumpow_ethw,
  etherzero_etz,
  ethereumclassic_etc,
  callisto_clo,
  bankex_bkx,
  cardano_ada,
  monero_xmr,
  aragon_ant,
  basicattentiontoken_bat,
  bancor_bnt,
  civic_cvc,
  district0x_dnt,
  gnosis_gno,
  golem_gnt,
  golem_glm,
  matchpool_gup,
  melon_mln,
  numeraire_nmr,
  omisego_omg,
  tenx_pay,
  ripiocreditnetwork_rcn,
  augur_rep,
  iexecrlc_rlc,
  salt_salt,
  status_snt,
  storj_storj,
  swarmcity_swt,
  trueusd_tusd,
  wings_wings,
  zerox_zrx,
  expanse_exp,
  viberate_vib,
  odyssey_ocn,
  polymath_poly,
  storm_storm,
  nano_nano,
  siacoin_sc,
  lbrycredits_lbc,
  tron_trx,
  nem_xem,
  lisk_lsk,
  stellar_xlm,
  btuprotocol_btu,
  cryptocomcoin_cro,
  multicollateraldai_dai,
  enjincoin_enj,
  hedgetrade_hedg,
  cred_lba,
  chainlink_link,
  loomnetwork_loom,
  maker_mkr,
  metal_mtl,
  oceanprotocol_ocean,
  quant_qnt,
  synthetixnetwork_snx,
  solve_solve,
  spendcoin_spnd,
  temco_temco,
  eos_eos,
  tezos_xtz,
  vechain_vet,
  stormx_stmx,
  augurv2_repv2,
  firmachain_fct,
  blocktrade_btt,
  quantumresistantledger_qrl,
  tap_xtp,
  compound_comp,
  usdcoin_usdc,
  cusd_cusd,
  algorand_algo,
  polkadot_dot,
  uniswapcoin_uni,
  aavecoin_aave,
  matic_matic,
  decentraland_mana,
  solana_sol,
  binance_bnb,
  avalanche_avax,
  flare_flr,
};

export { donate };
