import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { crypto } from '../../constants/crypto';
import IndexNav from '../IndexNav';
import DonateBox from './CryptoAddressBox';
import { CryptoAddress, JsonObject } from '../../types';
import http from '../../services/http-service';
import CreateCryptoAddress from './CreateCryptoAddress';
import { CircularProgress, Skeleton } from '@mui/material';
import { getAvatarProps, useUserStyles } from '../../common/user';
import { getUser, getUserId } from '../../common/auth';
import CreateLink from './CreateLink';
import { toast } from 'react-toastify';
import { Link } from '../../types/links';
import LinkBox from './LinkBox';

const User: React.FC = () => {
  const [user, setUser] = useState<JsonObject>({});
  const [hasFetchedCrypto, setHasFetchedCrypto] = useState(false);
  const [hasFetchedLinks, setHasFetchedLinks] = useState(false);
  const [lastCrypto, setLastCrypto] = useState(undefined);
  const [lastLinks, setLastLinks] = useState(undefined);
  const [cryptoAddresses, setCryptoAddresses] = useState<CryptoAddress[]>([]);
  const [links, setLinks] = useState<Link[]>([]);
  const { classes } = useUserStyles();

  const loadCrypto = async (lastObject?: JsonObject) => {
    try {
      const params: JsonObject = {};
      if (lastObject) params.start = JSON.stringify(lastObject);

      const userId = await getUserId();
      const result = await http.get(`users/${userId}/crypto-addresses`, {
        params,
      });

      setHasFetchedCrypto(true);
      setLastCrypto(result.data.last);
      setCryptoAddresses(
        lastObject
          ? [...result.data.items, ...cryptoAddresses]
          : result.data.items,
      );
    } catch (error) {
      toast.error('Failed to fetch crypto addresses');
    }
  };
  const loadLinks = async (lastObject?: JsonObject) => {
    try {
      const params: JsonObject = {};
      if (lastObject) params.start = JSON.stringify(lastObject);

      const userId = await getUserId();
      const result = await http.get(`users/${userId}/links`, {
        params,
      });

      setHasFetchedLinks(true);
      setLastLinks(result.data.last);
      setLinks(
        lastObject ? [...result.data.items, ...links] : result.data.items,
      );
    } catch (error) {
      toast.error('Failed to fetch links');
    }
  };

  const loadMore = async () => {
    const promises = [];
    if (!!lastLinks) promises.push(loadLinks(lastLinks));
    if (!!lastCrypto) promises.push(loadCrypto(lastCrypto));
    await Promise.all(promises);
  };
  useEffect(() => {
    (async () => {
      const storageUser = await getUser();
      setUser(storageUser);

      await Promise.all([loadCrypto(), loadLinks()]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const linkSkeleton = () => {
    return [...Array(4)].map((val: any, index: number) => (
      <Skeleton
        variant="rounded"
        animation="wave"
        width={'100%'}
        height={56}
        sx={{ marginTop: '6px', marginBottom: '6px' }}
        key={index}
      />
    ));
  };
  const cryptoAddressSkeleton = () => {
    return [...Array(4)].map((val: any, index: number) => (
      <Skeleton
        variant="rounded"
        animation="wave"
        width={260}
        height={316}
        sx={{ margin: '16px' }}
        key={index}
      />
    ));
  };
  return (
    <React.Fragment>
      <IndexNav />
      <Box
        height="100px"
        width="100%"
        sx={{ maxWidth: '680px' }}
        marginTop="4px"
        marginBottom="4px"
        margin="auto"
        boxShadow={0}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={!!lastCrypto || !!lastLinks}
          loader={<CircularProgress />}
        >
          <Grid
            container={true}
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction="column"
            className={classes.gridBox}
          >
            <Grid item={true} xl={2} lg={2} md={2} sm={2}>
              <Avatar
                alt={user.username}
                className={classes.bigAvatar}
                {...getAvatarProps(user)}
              />
            </Grid>
            <Grid item={true} xl={2} lg={2} md={2} sm={2}>
              <Typography variant="body1" align="center">
                <b>{user.name}</b> (
                <a
                  href={`/u/${user.username}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: 'inherit' }}
                >
                  @{user.username}
                </a>
                )
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container={true}
            spacing={2}
            alignItems="center"
            justifyContent="center"
            marginTop="16px"
            className={classes.gridBox}
          >
            {!hasFetchedLinks && linkSkeleton()}
            {hasFetchedLinks && <CreateLink loadLinks={loadLinks} />}
            {links.map(link => (
              <LinkBox
                linkId={link.id}
                key={link.id}
                image={link.image}
                url={link.url}
                title={link.title}
                loadLinks={loadLinks}
              />
            ))}
            {!hasFetchedCrypto && cryptoAddressSkeleton()}
            {hasFetchedCrypto && (
              <CreateCryptoAddress loadCrypto={loadCrypto} />
            )}
            {cryptoAddresses.map(cryptoAddress => (
              <DonateBox
                cryptoAddressId={cryptoAddress.id}
                address={cryptoAddress.address}
                coin={crypto[cryptoAddress.name]}
                key={cryptoAddress.id}
                loadCrypto={loadCrypto}
              />
            ))}
          </Grid>
        </InfiniteScroll>
      </Box>
    </React.Fragment>
  );
};

export default User;
