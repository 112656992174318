import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../constants/const';

export const requestInterceptor = axios.interceptors.request;
export const responseInterceptor = axios.interceptors.response;

type requestInterceptorArgs = Parameters<typeof axios.interceptors.request.use>;
type responseInterceptorArgs = Parameters<
  typeof axios.interceptors.response.use
>;

export class HttpService {
  private baseUrl = API_URL;

  constructor() {
    this.addRequestInterceptor(req => {
      // @ts-ignore
      req.headers = {
        ...req.headers,
        'Content-Type': 'application/json',
      };

      return req;
    });
  }

  addRequestInterceptor(...args: requestInterceptorArgs) {
    const interceptor = axios.interceptors.request.use(...args);
    return () => axios.interceptors.request.eject(interceptor);
  }

  addResponseInterceptor(...args: responseInterceptorArgs) {
    const interceptor = axios.interceptors.response.use(...args);
    return () => axios.interceptors.response.eject(interceptor);
  }

  get<T = any>(url: string, options: AxiosRequestConfig = {}) {
    return axios.get<T>(`${this.baseUrl}${url}`, options);
  }

  post<T = any>(url: string, data: any, options: AxiosRequestConfig = {}) {
    return axios.post<T>(`${this.baseUrl}${url}`, data, options);
  }

  put<T = any>(url: string, data: any, options: AxiosRequestConfig = {}) {
    return axios.put<T>(`${this.baseUrl}${url}`, data, options);
  }

  patch<T = any>(url: string, data: any, options: AxiosRequestConfig = {}) {
    return axios.patch<T>(`${this.baseUrl}${url}`, data, options);
  }

  delete<T = any>(url: string) {
    return axios.delete<T>(`${this.baseUrl}${url}`, {
      data: { workaround: 'content-type in axios' },
    });
  }
}

const http = new HttpService();

export default http;
