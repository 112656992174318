import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

import IndexNav from '../IndexNav';
import Footer from './Footer';

const Home: React.FC = () => {
  return (
    <div className="page">
      <IndexNav />
      <Box className="page-users home">
        <Grid
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Grid item={true}>
            <Typography variant="h3">Create your space on the web</Typography>
          </Grid>
          <Grid item={true}>
            <Typography>
              Elevate your online presence effortlessly with our cutting-edge
              profile link in bio service. Simplify the way you connect with
              your audience and make a lasting impression.
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography>
              <b>🌐 Seamless Simplicity:</b> No more swapping out links or
              updating your bio constantly. With our user-friendly dashboard,
              you can manage all your important links in one place, hassle-free.
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography>
              <b>💼 Link Cryptocurrency Addresses:</b> Revolutionize your
              interactions by seamlessly integrating cryptocurrency addresses
              alongside your URLs. Whether it's Bitcoin, Ethereum, or your
              favorite altcoin, your followers can now support you directly with
              just a click.
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography>
              <b>📈 Boost Engagement:</b> Maximize your impact and engagement
              with our customizable buttons. Drive traffic to your latest
              content, product launches, or donation campaigns, all in a single
              link. Your audience will love the convenience.
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography>
              <b>🎨 Personalize and Impress:</b> Make your bio link an extension
              of your brand. Customize the appearance and layout to reflect your
              unique style. Leave a lasting impression that truly represents
              you.
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography>
              <b>📱 Mobile-Optimized:</b> In today's fast-paced world, mobile
              matters. Rest assured, your profile link will shine on any device,
              ensuring a seamless experience for your followers no matter where
              they find you.
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography>
              Join the ranks of influencers, content creators, entrepreneurs,
              and artists who have already transformed their online presence
              with our profile link in bio service. It's time to simplify,
              captivate, and connect like never before. Sign up now and make
              every click count! 🎉
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
};

export default Home;
