import { Component, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from 'react-router-dom';

import User from './components/User';
import UserMe from './components/UserMe';
import Home from './components/Home';
import Login from './components/Login';
import Signup from './components/Signup';
import Reset from './components/Reset';
import VerifyEmail from './components/VerifyEmail';
import RequireAuth from './components/RequireAuth';
import Profile from './components/Profile';
import useRedirect from './hooks/use-redirect';
import Privacy from './components/Privacy';

const ProfileRoute = (props: any) => (
  <RequireAuth type="private">
    <Profile {...props} />
  </RequireAuth>
);
const UserMeRoute = (props: any) => (
  <RequireAuth type="private">
    <UserMe {...props} />
  </RequireAuth>
);
const LoginRoute = (props: any) => (
  <RequireAuth type="public">
    <Login {...props} />
  </RequireAuth>
);
const SignupRoute = (props: any) => (
  <RequireAuth type="public">
    <Signup {...props} />
  </RequireAuth>
);
const ResetRoute = (props: any) => (
  <RequireAuth type="public">
    <Reset {...props} />
  </RequireAuth>
);
const VerifyEmailRoute = (props: any) => (
  <RequireAuth type="public">
    <VerifyEmail {...props} />
  </RequireAuth>
);

const RedirectToUser = () => {
  let { username } = useParams();
  const redirect = useRedirect();

  useEffect(() => {
    console.log(username);

    redirect(`/u/${username}`);
  }, [redirect, username]);
  return <div />;
};

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/u">
            <Route path="/u/" element={<Navigate to="/" />} />
            <Route path="/u/me" Component={UserMeRoute} />
            <Route path="/u/:username" Component={User} />
          </Route>
          <Route path="/profile" Component={ProfileRoute} />
          <Route path="/login" Component={LoginRoute} />
          <Route path="/signup" Component={SignupRoute} />
          <Route path="/reset" Component={ResetRoute} />
          <Route path="/privacy" Component={Privacy} />
          <Route path="/verify" Component={VerifyEmailRoute} />
          <Route path="/:username" Component={RedirectToUser} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default Router;
