import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import http from '../../../services/http-service';
import { useLinkBoxStyle } from '../../../common/user';
import { toast } from 'react-toastify';

export default function LinkBox(props: any): JSX.Element {
  const { classes } = useLinkBoxStyle();
  const deleteLink = async () => {
    const toastId = toast.loading('Deleting link');
    await http.delete(`links/${props.linkId}`);
    props.loadLinks();
    toast.dismiss(toastId);
  };
  return (
    <Grid
      container={true}
      alignItems="center"
      justifyContent="space-between"
      className={classes.linkBox}
    >
      <Grid item={true} lg={1} md={1} sm={2} xs={2}>
        {props.image && (
          <Avatar
            aria-label={props.title}
            src={props.image}
            sx={{ marginLeft: '4px' }}
          />
        )}
      </Grid>
      <Grid
        item={true}
        lg={10}
        md={10}
        sm={8}
        xs={8}
        className={classes.link}
        component={'a'}
        href={props.url}
        target="_blank"
        rel="noreferrer"
      >
        <Typography variant="body1" align="center">
          {props.title}
        </Typography>
      </Grid>
      <Grid
        item={true}
        lg={1}
        md={1}
        sm={2}
        xs={2}
        textAlign="right"
        sx={{ paddingRight: '4px' }}
      >
        <IconButton aria-label="delete" onClick={deleteLink}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
