import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";

export const DeleteIconStyled = styled(DeleteIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: white;
  border-radius: 4px;
  transition: 150ms opacity ease-out;

  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
`;
