import { useNavigate } from 'react-router-dom';

interface RedirectOptions {
  push?: boolean;
}

export default function useRedirect() {
  const navigate = useNavigate();

  return function redirect(to: string, { push = true }: RedirectOptions = {}) {
    if (push) {
      navigate(to);
    } else {
      navigate(to, { replace: true });
    }
  };
}
