import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import IndexNavComponent from '../IndexNav';
import { Divider, Grid, TextField, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { JsonObject } from '../../types';
import { schema } from '../../constants/schema';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE } from '../../constants/locale';
import useRedirect from '../../hooks/use-redirect';
import GoogleIcon from '../../assets/images/GoogleIcon';
import { signInWithRedirect, signUp } from 'aws-amplify/auth';

const useStyles = makeStyles()(() => ({
  container: {
    borderTop: '1px solid rgb(229, 227, 221)',
    borderBottom: '1px solid rgb(229, 227, 221)',
    borderLeft: '1px solid rgb(229, 227, 221)',
    borderRight: '1px solid rgb(229, 227, 221)',
    borderRadius: '4px',
    padding: '16px',
  },
}));

const Signup: React.FC = () => {
  const { classes } = useStyles();
  const redirect = useRedirect();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data: JsonObject) => {
    const toastId = toast.loading('Signing up');
    try {
      await signUp({
        username: data.email.trim(),
        password: data.password.trim(),
        options: {
          userAttributes: {
            name: data.name.trim(),
          },
        },
      });
      toast.dismiss(toastId);
      toast.update(toastId, {
        render: 'Signed up successfully',
        type: 'success',
        isLoading: false,
        autoClose: 2000,
      });
      redirect('/login');
    } catch (error) {
      toast.update(toastId, {
        render: (error as Error).message,
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    }
  };
  return (
    <React.Fragment>
      <IndexNavComponent />
      <Container maxWidth="xs" className={classes.container}>
        <Grid
          container={true}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item={true} lg={12} md={12} sm={12} xs={12} textAlign="center">
            <Typography variant="h5">Sign up</Typography>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="email"
              label="Email"
              margin="dense"
              type="email"
              fullWidth={true}
              error={!!errors?.email}
              helperText={!!errors?.email && ERROR_MESSAGE.email}
              {...register('email', schema.email)}
            />
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="name"
              label="Name"
              margin="dense"
              type="text"
              fullWidth={true}
              error={!!errors?.name}
              helperText={!!errors?.name && ERROR_MESSAGE.required}
              {...register('name', { required: true })}
            />
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="password"
              label="Password"
              margin="dense"
              fullWidth={true}
              type="password"
              error={!!errors?.password}
              helperText={!!errors?.password && ERROR_MESSAGE.password}
              {...register('password', { ...schema.password })}
            />
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth={true}
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              Sign up
            </Button>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Divider>OR</Divider>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Button
              variant="outlined"
              type="submit"
              fullWidth={true}
              startIcon={<GoogleIcon />}
              // @ts-ignore
              onClick={() => signInWithRedirect({ provider: 'Google' })}
              sx={{
                textTransform: 'none',
              }}
            >
              Sign in with Google
            </Button>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12} textAlign="center">
            <Typography variant="body1">
              See our{' '}
              <a href="/privacy" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </Typography>
          </Grid>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item={true} lg={6} md={6} sm={6} xs={6} textAlign="center">
            <NavLink to="/reset">
              <Typography variant="body1">Reset Password</Typography>
            </NavLink>
          </Grid>
          <Grid item={true} lg={6} md={6} sm={6} xs={6} textAlign="center">
            <NavLink to="/login" className="active">
              <Typography variant="body1">Login</Typography>
            </NavLink>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Signup;
