import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import useRedirect from "../../hooks/use-redirect";
import { getCurrentUser } from "aws-amplify/auth";

interface RequireAuthProps {
  type: "public" | "private";
  children?: React.ReactNode;
}

const RequireAuth = (props: RequireAuthProps) => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const redirect = useRedirect();

  useEffect(() => {
    getCurrentUser()
      .then(() => {
        if (props.type === "public") {
          redirect("/");
        } else {
          setUserLoggedIn(true);
        }
      })
      .catch(() => {
        if (props.type === "private") {
          redirect("/");
        } else {
          setUserLoggedIn(true);
        }
      });
  }, [props.type, redirect]);

  return (
    <React.Fragment>
      {userLoggedIn ? (
        props.children
      ) : (
        <LinearProgress
          color="primary"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
          }}
        />
      )}
    </React.Fragment>
  );
};

export default RequireAuth;
