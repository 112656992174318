import React from "react";
import useSignUpParameters from "../../hooks/use-sign-up-parameters";
import ChangePassword from "./ChangePassword";
import ResetPassword from "./ResetPassword";

const Reset: React.FC = () => {
  const { verifyCode, email } = useSignUpParameters();

  return verifyCode && email ? (
    <ChangePassword verifyCode={verifyCode} email={email} />
  ) : (
    <ResetPassword />
  );
};

export default Reset;
